<template>
  <div>
    <div v-if="loading">
      <b-spinner></b-spinner>
    </div>
    <div v-if="!loading">
      <h4 class="text-danger" v-if="needBalanceConfig">Требуется изменение балансировки портфеля!</h4>
      <b-tabs justified pills>
        <b-tab title="Текущая балансировка">
          <h4>Текущая балансировка портфеля</h4>
          <b-row>
            <b-col sm="6">
              <h5 style="text-align: center">Текущие значения</h5>
              <chart :data="chartCurrent"></chart>
            </b-col>
            <b-col sm="6">
              <h5 style="text-align: center">Целевые значения</h5>
              <chart :data="chartTarget"></chart>
            </b-col>
          </b-row>
          <b-row>
            <b-table
                responsive
                striped
                hover
                :items="balance.items"
                :fields="balance.fields"
                style="margin-top: 1%"
            >
              <template #cell(currentBalance)="data">
                {{ data.value | numberFormat }}%
              </template>
              <template #cell(targetBalance)="data">
                {{ data.value | numberFormat }}%
              </template>
            </b-table>
          </b-row>
        </b-tab>
        <b-tab title="Количественное распределение портфеля" lazy>
          <h4>Распределение портфеля по агентствам и статусам</h4>
          <column-chart :data="distribution"></column-chart>
        </b-tab>
        <b-tab title="Изменение балансировки">
          <h4>Изменение значений балансировки</h4>
          <div style="margin-top: 1%">
            <p class="text-danger" v-if="changeInfo.show">{{ changeInfo.message }}</p>
            <b-row style="text-align: center">
              <b-col sm="6">
                <h5>Название агентства</h5>
              </b-col>
              <b-col sm="6">
                <h5>Целевой баланс портфеля</h5>
              </b-col>
            </b-row>
            <b-row v-for="config in newConfig" :key="config.id" style="margin-top: 1%">
              <b-col sm="6" style="text-align: center">
                <strong>{{ config.name }}</strong>
              </b-col>
              <b-col sm="6">
                <b-form-group>
                  <b-form-input type="number" v-model.number="config.balance" min="0" :step="1"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <div v-if="!changeLoading">
              <b-button v-if="!changeInfo.show" variant="success" style="margin-top: 1%" @click="changeBalance">Обновить</b-button>
            </div>
            <b-spinner v-if="changeLoading"></b-spinner>
          </div>
        </b-tab>
      </b-tabs>
    </div>
    <ErrorHandler :message="errorMessage"></ErrorHandler>
  </div>
</template>

<script>
import rpc from "../../rpc";
import Filters from "../../mixins/filters";
import Chart from "../Chart";
import ChartModel from "../../mixins/chart-model";
import ColumnChart from "../ColumnChart";
import ErrorHandler from "../ErrorHandler";

export default {
  name: "Balance",
  components: {ErrorHandler, ColumnChart, Chart},
  data() {
    return {
      errorMessage: null,
      changeLoading: false,
      distribution: new ChartModel(),
      chartCurrent: new ChartModel(),
      chartTarget: new ChartModel(),
      loading: true,
      needBalanceConfig: false,
      newConfig: [],
      changeInfo: {
        show: false,
        message: null,
      },
      balance: {
        items: [],
        fields: [
          {
            key: 'name',
            label: 'Название агентства'
          },
          {
            key: 'currentBalance',
            label: 'Текущий баланс агентства'
          },
          {
            key: 'targetBalance',
            label: 'Целевой баланс портфеля'
          },
        ]
      }
    }
  },
  methods: {
    getBalanceInfo() {
      this.loading = true
      rpc.getBalanceInfo().then((result) => {
        this.processBalanceInfoResult(result)
        this.loading = false
      })
    },
    processBalanceInfoResult(result) {
      this.balance.items = result.balance
      this.needBalanceConfig = result.needBalanceConfig
      this.newConfig = []
      this.chartCurrent.clear()
      this.chartTarget.clear()
      this.distribution.clear()
      for (let k in result.balance) {
        this.chartCurrent.add(result.balance[k].currentBalance, result.balance[k].name)
        this.chartTarget.add(result.balance[k].targetBalance, result.balance[k].name)
        this.distribution.add(result.balance[k].currentCount, result.balance[k].name)
        this.newConfig.push({
          name: result.balance[k].name,
          id: result.balance[k].id,
          balance: result.balance[k].balance,
          targetBalance: result.balance[k].targetBalance
        })
      }
      this.distribution.add(result.notBalanced, 'Не распределено')
      this.distribution.add(result.noInn, 'Нет ИНН')
    },
    changeBalance() {
      this.changeLoading = true
      let data = []
      for (let k in this.newConfig) {
        data.push({
          id: this.newConfig[k].id,
          balance: +this.newConfig[k].balance
        })
      }
      rpc.changeCollectorsBalance(data).then((result) => {
        this.processBalanceInfoResult(result)
        this.loading = false
        this.changeLoading = false
      }).catch((err) => {
        if (err.code === -32602) {
          if (err.message !== 'Validation error') {
            this.changeInfo.message = err.message
          } else if (Array.isArray(err.data)) {
            let errors = []
            for (let k in err.data) {
              if (errors.indexOf(err.data[k].message) === -1) {
                errors.push(err.data[k].message)
              }
            }
            this.changeInfo.message = errors.join(';')
          }
        } else {
          this.changeInfo.message = 'Внутренняя ошибка'
          this.errorMessage = 'Произошла ошибка'
        }
        this.changeInfo.show = true
        this.changeLoading = false
        this.loading = false
        setTimeout(() => {
          this.changeInfo.show = false
          this.changeInfo.message = null
          this.errorMessage = null
        }, 1500)
      })
    }
  },
  mounted() {
    this.getBalanceInfo()
  },
  filters: {
    numberFormat(val) {
      return Filters.numberViewFormat(val)
    }
  }
}
</script>

<style scoped>

</style>