<template>
  <div>
    <b-spinner v-if="loading"></b-spinner>
    <div v-if="!loading">
      <b-row>
        <b-col sm="3">
          <b-form-group label="Агентство">
            <b-form-select class="form-control" v-model="agency" :disabled="table.busy">
              <b-form-select-option :value="null">Все</b-form-select-option>
              <b-form-select-option v-for="a in agencies" :key="a.id" :value="a.id">{{ a.name }}</b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col sm="3">
          <b-form-group label="Тип">
            <b-form-select class="form-control" v-model="type" :disabled="table.busy">
              <b-form-select-option :value="null">Все</b-form-select-option>
              <b-form-select-option v-for="t in types" :key="t.code" :value="t.code">{{ t.name }}</b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-table
        responsive
        striped
        hover
        :busy="table.busy"
        :current-page="table.page"
        :items="table.items"
        :fields="table.fields"
      >
        <template #cell(action)="data">
          <b-button variant="success" @click="downloadReport(data.item.id)">Скачать</b-button>
        </template>
      </b-table>
      <b-pagination
          pills
          v-model="table.page"
          :total-rows="table.count"
          :per-page="table.limit"
      ></b-pagination>
    </div>
    <error-handler :message="errorMessage"></error-handler>
  </div>
</template>

<script>
import Table from "../../mixins/table";
import rpc from "../../rpc";
import ErrorHandler from "../ErrorHandler";

export default {
  name: "CollectorReports",
  components: {ErrorHandler},
  data() {
    return {
      errorMessage: null,
      loading: true,
      table: new Table({
        fields: [
          {
            key: 'type',
            label: 'Тип'
          },
          {
            key: 'name',
            label: 'Агентство'
          },
          {
            key: 'status',
            label: 'Статус',
          },
          {
            key: 'created',
            label: 'Дата создания'
          },
          {
            key: 'completed',
            label: 'Дата завершения'
          },
          {
            key: 'action',
            label: 'Действия'
          }
        ]
      }),
      agencies: [],
      agency: null,
      types: [],
      type: null,
    }
  },
  methods: {
    processTable() {
      this.table.busy = true
      rpc.getCollectorReports(this.agency, this.type, this.table.limit, this.table.calculateOffset()).then((result) => {
        this.table.busy = false
        this.table.items = result.data
        this.table.count = result.count
        this.agencies = result.agencies
        this.types = result.types
        this.loading = false
      }).catch(() => {
        this.errorMessage = 'Ошибка получения списка коллекторских выгрузок'
        this.table.busy = false
        this.loading = false
        setTimeout(() => {
          this.errorMessage = null
        }, 2000)
      })
    },
    downloadReport(id) {
      window.open('/download-collector-report/' + id, '_blank')
    }
  },
  mounted() {
    this.processTable()
  },
  watch: {
    'table.page': function () {
      this.processTable()
    },
    agency: function () {
      this.processTable()
    },
    type: function () {
      this.processTable()
    }
  }
}
</script>

<style scoped>

</style>