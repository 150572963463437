<template>
  <div>
    <b-row>
      <b-col sm="8">
        <b-card title="Сформированные выгрузки">
          <b-card-body>
            <collector-payment-report-table ref="table"></collector-payment-report-table>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col sm="4">
        <b-card title="Создание выгрузки">
          <b-card-body>
            <b-spinner v-if="loading"></b-spinner>
            <div v-if="!loading">
              <p class="text-success" v-if="feedback">{{ feedback }}</p>
              <p class="text-danger" v-if="error">{{ error }}</p>
              <b-form-group
                v-for="(item, name) in createForm"
                :key="name"
                :label="item.label"
                :invalid-feedback="item.feedback"
                :state="item.state"
              >
                <b-form-select class="form-control" v-if="item.type === 'select'" v-model="item.value">
                  <b-form-select-option :value="null">Все</b-form-select-option>
                  <b-form-select-option v-for="el in item.items" :value="el.id" :key="el.id">{{ el.name }}</b-form-select-option>
                </b-form-select>
                <b-form-input
                  v-if="item.type === 'date'"
                  type="date"
                  :placeholder="item.placeholder"
                  v-model="item.value"
                  :state="item.state"
                  @input="clearError(item)"
                ></b-form-input>
              </b-form-group>
              <b-button-group>
                <b-button variant="success" @click="createReport">Создать</b-button>
                <b-button variant="warning" @click="clearAll">Сброс</b-button>
              </b-button-group>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import FormItem from "../../mixins/form-item";
import rpc from "../../rpc";
import FormUtils from "../../mixins/form-utils";
import CollectorPaymentReportTable from "./CollectorPaymentReportTable";

export default {
  name: "CollectorPaymentReport",
  components: {CollectorPaymentReportTable},
  data() {
    return {
      feedback: null,
      loading: true,
      error: null,
      createForm: {
        dateFrom: new FormItem({label: 'Дата от', placeholder: 'Выберите дату от', type: 'date'}),
        dateTo: new FormItem({label: 'Дата до', placeholder: 'Выберите дату до', type: 'date'}),
        agency: new FormItem({label: 'Агентство', placeholder: 'Выберите агентство', type: 'select'}),
      }
    }
  },
  methods: {
    clearAll() {
      for (let k in this.createForm) {
        this.createForm[k].value = null
        this.createForm[k].state = null
        this.createForm[k].feedback = null
      }
    },
    clearError(item) {
      item.state = null
      item.feedback = null
    },
    createReport() {
      this.loading = true
      rpc.createCollectorPaymentReport(
          this.createForm.dateFrom.value,
          this.createForm.dateTo.value,
          this.createForm.agency.value
      ).then((res) => {
        this.feedback = res.feedback
        setTimeout(() => {
          this.feedback = null
        }, 3000)
        this.clearAll()
        this.$refs.table.reset()
        this.loading = false
      }).catch((err) => {
        if (Array.isArray(err.data)) {
          FormUtils.addValidationErrors(this.createForm, err.data)
        } else {
          this.clearAll()
          this.error = 'Произошла ошибка'
          setTimeout(() => {
            this.error = null
          }, 3000)
        }
        this.loading = false
      })
    }
  },
  mounted() {
    rpc.getCollectorReports(null, null, 1, 0).then((res) => {
      this.createForm.agency.items = res.agencies
      this.loading = false
    }).catch(() => {
      this.loading = false
    })
  }
}
</script>

<style scoped>

</style>