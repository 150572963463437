<template>
  <div>
    <b-container>
      <b-row>
        <b-col sm="12">
          <b-card title="Функционал администратора">
            <b-card-text>
              <b-tabs justified pills>
                <b-tab title="Балансировка" lazy>
                  <h4 style="text-align: center">Просмотр и настройка балансировки</h4>
                  <balance style="margin-top: 1%"></balance>
                </b-tab>
                <b-tab title="Коллекторы" lazy>
                  <h4 style="text-align: center">Просмотр и создание коллекторских агентств</h4>
                  <collectors style="margin-top: 1%"></collectors>
                </b-tab>
                <b-tab title="Параметры" lazy>
                  <h4 style="text-align: center; margin-top: 1%">Просмотр и настройка параметров</h4>
                  <parameters style="margin-top: 1%"></parameters>
                </b-tab>
                <b-tab title="Федресурс" lazy>
                  <h4 style="text-align: center; margin-top: 1%">Информация об отчётах в Федресурс</h4>
                  <fed-resurs-main></fed-resurs-main>
                </b-tab>
                <b-tab title="Займы" lazy>
                  <h4 style="text-align: center; margin-top: 1%">Информация о займах внешних коллекторов</h4>
                  <loans></loans>
                </b-tab>
                <b-tab title="Администраторы" lazy>
                  <h4 style="text-align: center; margin-top: 1%">Управление администраторами сервиса</h4>
                  <administrators></administrators>
                </b-tab>
              </b-tabs>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Collectors from "../components/admin/Collectors";
import Balance from "../components/admin/Balance";
import Parameters from "../components/admin/Parameters";
import Loans from "../components/admin/Loans";
import Administrators from "../components/admin/Administrators";
import FedResursMain from "../components/admin/FedResursMain";
export default {
  name: "AdminMain",
  components: {FedResursMain, Administrators, Loans, Parameters, Balance, Collectors},
}
</script>

<style scoped>

</style>