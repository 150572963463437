<template>
  <div>
    <b-table
      responsive
      striped
      hover
      :busy="table.busy"
      :current-page="table.page"
      :items="table.items"
      :fields="table.fields"
    ></b-table>
    <b-pagination
      pills
      v-model="table.page"
      :total-rows="table.count"
      :per-page="table.limit"
    ></b-pagination>
    <error-handler :message="errorMessage"></error-handler>
  </div>
</template>

<script>
import Table from "../../mixins/table";
import rpc from "../../rpc";
import ErrorHandler from "../ErrorHandler";

export default {
  name: "FedResursErrors",
  components: {ErrorHandler},
  data() {
    return {
      errorMessage: null,
      table: new Table({
        fields: [
          {
            key: 'id',
            label: 'ID события',
          },
          {
            key: 'type',
            label: 'Тип события'
          },
          {
            key: 'reportId',
            label: 'ID отчёта'
          },
          {
            key: 'createdAt',
            label: 'Дата создания отчёта'
          },
          {
            key: 'completedAt',
            label: 'Дата завершения отчёта'
          },
          {
            key: 'error',
            label: 'Ошибка'
          },
          {
            key: 'loanId',
            label: 'ID займа'
          }
        ]
      })
    }
  },
  methods: {
    getTable() {
      this.table.busy = true
      rpc.getErrorSendActions(this.table.limit, this.table.calculateOffset()).then((result) => {
        this.table.count = result.count
        this.table.items = result.data
        this.table.busy = false
      }).catch((err) => {
        this.table.items = []
        this.errorMessage = err.message
        this.table.busy = false
        setTimeout(() => {
          this.errorMessage = null
        }, 2000)
      })
    }
  },
  mounted() {
    this.getTable()
  }
}
</script>

<style scoped>

</style>