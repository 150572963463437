<template>
  <div>
    <div v-if="loading"><b-spinner></b-spinner></div>
    <div v-if="!loading">
      <b-row>
        <b-col sm="4" style="text-align: center">
          <h5>Название параметра</h5>
        </b-col>
        <b-col sm="4" style="text-align: center">
          <h5>Значение параметра</h5>
        </b-col>
      </b-row>
      <b-row v-for="parameter in parameters" :key="parameter.codeVal" style="margin-top: 1%">
        <b-col sm="4" style="text-align: center">
          <strong>{{ parameter.name }}</strong>
        </b-col>
        <b-col sm="4">
          <b-form-group
              :state="parameter.value.state"
              :invalid-feedback="parameter.value.feedback"
          >
            <b-form-input v-model="parameter.value.value" :state="parameter.value.state" @input="clearParameterError(parameter)"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col sm="4">
          <b-form-group>
            <b-button variant="success" @click="updateParameter(parameter)" v-if="!parameter.loading">Обновить</b-button>
            <b-spinner v-if="parameter.loading"></b-spinner>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import rpc from "../../rpc";
import Parameter from "../../mixins/parameter";
import FormUtils from "../../mixins/form-utils";

export default {
  name: "Parameters",
  data() {
    return {
      loading: true,
      parameters: [],
    }
  },
  methods: {
    clearParameterError(parameter) {
      parameter.value.state = null
      parameter.value.feedback = null
    },
    updateParameter(parameter) {
      parameter.loading = true
      rpc.updateParameter(parameter.codeVal, parameter.value.value).then((result) => {
        parameter.apply(result)
        parameter.loading = false
      }).catch((err) => {
        if (err.code === -32602) {
          FormUtils.addValidationErrors(parameter, err.data)
          parameter.loading = false
        }
      })
    },
    getParameters() {
      this.loading = true
      rpc.getParameters().then((result) => {
        this.parameters = []
        for (let k in result) {
          this.parameters.push(new Parameter(result[k]))
        }
        this.loading = false
      })
    }
  },
  mounted() {
    this.getParameters()
  }
}
</script>

<style scoped>

</style>