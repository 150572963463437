import Filters from "./filters";

export default class FormItem {
    value
    feedback
    state
    label
    placeholder
    filterType
    type
    items

    constructor(data = {}) {
        this.value = data.value || null
        this.feedback = data.feedback || null
        this.state = data.state || null
        this.label = data.label || null
        this.placeholder = data.placeholder || null
        this.filterType = data.filter || null
        this.type = data.type || 'text'
        this.items = data.items || []
    }

    filter(val, evt) {
        switch (evt.srcElement.attributes['filter-type'].value) {
            case 'loanId':
                return Filters.loanIdFilter(val)
            case 'phone':
                return Filters.phoneFilter(val)
            case 'email':
                return Filters.emailFilter(val)
            case 'name':
                return Filters.nameFilter(val)
            case 'login':
            case 'password':
                return Filters.loginPasswordFilter(val)
            case 'address':
                return Filters.addressFilter(val)
            case 'inn':
                return Filters.ogrnInnFilter(val, 10)
            case 'ogrn':
                return Filters.ogrnInnFilter(val, 13)
            case 'gender':
                return Filters.genderFilter(val)
            case 'date':
                return Filters.dateFilter(val)
            default:
                return val
        }
    }
}