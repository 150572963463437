<template>
  <apexchart type="bar" :series="chartSeries" :options="chartOptions" height="350"></apexchart>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import ChartModel from "../mixins/chart-model";

export default {
  name: "ColumnChart",
  components: {
    'apexchart': VueApexCharts
  },
  props: {
    data: {
      type: ChartModel,
      required: true,
    }
  },
  computed: {
    chartSeries: function () {
      return [{
        name: 'Количество займов',
        data: this.data.series
      }]
    },
    chartOptions: function () {
      return {
        chart: {
          type: 'bar',
          height: 350,
        },
        plotOptions: {
          bar: {
            columnWidth: '55%',
            distributed: true
          }
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: this.data.labels,
          labels: {
            show: false
          }
        },
        yaxis: {
          title: {
            text: 'Количество займов'
          }
        },
        fill: {
          opacity: 1,
        },
      }
    }
  },
  mounted() {
    window.dispatchEvent(new Event('resize'))
  }
}
</script>

<style scoped>

</style>