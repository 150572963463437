export default class Parameter {
    id
    code
    codeVal
    value
    name
    loading

    constructor(data = {}) {
        this.apply(data)
    }

    apply(data) {
        this.loading = false
        this.id = data.id || null
        this.code = {
            state: null,
            feedback: null,
            value: data.code || null
        }
        this.codeVal = data.code || null
        this.value = {
            state: null,
            feedback: null,
            value: data.value || null
        }
        this.name = data.name || null
    }
}