<template>
  <div>
    <div v-if="!loading">
      <b-form-group
          v-for="(item, name) in form"
          :key="name"
          :label="item.label"
          :invalid-feedback="item.feedback"
          :state="item.state && !!item.value"
      >

        <b-form-input
            :filter-type="item.type"
            type="text"
            :placeholder="item.placeholder"
            v-model="item.value"
            :state="item.state && !!item.value"
            :formatter="item.filter"
            @input="clearError(item)"
        ></b-form-input>
      </b-form-group>
      <b-button variant="success" @click="create" v-if="canCreate || isEdit">{{ isEdit ? 'Сохранить' : 'Создать' }}
      </b-button>
    </div>
    <div v-if="loading">
      <b-spinner></b-spinner>
    </div>
    <b-alert v-model="createInfo.show" class="position-fixed fixed-top m-0 rounded-0" :variant="createInfo.variant">
      {{ createInfo.message }}
    </b-alert>
  </div>
</template>

<script>
import FormItem from "../../mixins/form-item";
import FormUtils from "../../mixins/form-utils";
import rpc from "../../rpc";

const formFields = [
  {
    fieldName: "name",
    placeholder: "Введите название",
    label: "Название",
    type: "name"
  },
  {
    fieldName: "email",
    placeholder: "Введите E-mail",
    label: "E-mail",
    type: "email"
  },
  {
    fieldName: "phone",
    placeholder: "Введите телефон",
    label: "Телефон",
    type: "phone",
    feedback: 'phoneInvalidFeedback'
  },
  {
    fieldName: "inn",
    placeholder: "Введите ИНН",
    label: "ИНН",
    type: "inn"
  },
  {fieldName: "ogrn", placeholder: 'Введите ОГРН', label: 'ОГРН', type: 'ogrn'},
  {fieldName: "address", placeholder: 'Введите адрес', label: 'Адрес'},
  {
    fieldName: "headType",
    placeholder: 'Введите тип руководителя (директор, генеральный директор и т.д.)',
    label: 'Тип руководителя',
    type: 'name'
  },
  {fieldName: "headName", placeholder: 'Введите ФИО руководителя', label: 'ФИО руководителя', type: 'name'},
  {
    fieldName: "headGender",
    placeholder: 'Введите пол руководителя (m для мужского, f для женского)',
    label: 'Пол руководителя',
    type: 'gender'
  },
  {
    fieldName: "mainDocument",
    placeholder: 'Введите наименование уставного документа',
    label: 'Наименование уставного документа',
    type: 'name'
  },
  {
    fieldName: "agentDocName",
    placeholder: 'Введите наименование агентского договора',
    label: 'Наименование агентского договора'
  },
  {
    fieldName: "agentDocDate",
    placeholder: 'Введите дату агентского договора',
    label: 'Дата агентского договора',
    type: 'date'
  },
  {fieldName: "login", placeholder: 'Введите логин', label: 'Логин', type: 'login'},
  {fieldName: "password", placeholder: 'Введите пароль', label: 'Пароль', type: 'password'}
]

const createFormItem = (formItem) => {
  return new FormItem(formItem)
}

const createForm = (formFields, initForm) => {
  return Object.fromEntries(formFields.map((field) => {
    if (initForm && (field.fieldName in initForm)) {
      return [field.fieldName, createFormItem({...field, value: initForm[field.fieldName]})]
    }
    return [field.fieldName, createFormItem(field)]
  }))
}

export default {
  name: "CreateCollector",
  props: {
    initForm: {
      required: false,
      type: Object
    },
    isEdit: {
      required: false,
      type: Boolean
    }
  },
  data() {
    return {
      loading: false,
      createInfo: {
        show: false,
        message: null,
        variant: 'success'
      },
      form: createForm(formFields.filter(f => this.isEdit ? f.fieldName !== 'login' && f.fieldName !== 'password' : true), this.initForm)
    }
  },
  methods: {
    clearError(item) {
      FormUtils.clearSingleValidationError(item)
    },

    create() {
      this.loading = true
      if (this.isEdit) {
        delete this.form.password
        delete this.form.login
      }
      // eslint-disable-next-line no-unexpected-multiline
      (this.isEdit ?
          rpc.editCollectorAgency.apply(rpc, [this.initForm.id, FormUtils.formToParams(this.form)])
          : rpc.createCollectorAgency.apply(rpc, [FormUtils.formToParams(this.form)]))
          .then((result) => {
            this.createInfo.message = `${this.isEdit ? 'Сохранено' : 'Создано'} коллекторское агентство с названием "` + result.name + '"'
            this.createInfo.variant = 'success'
            this.createInfo.show = true
            setTimeout(() => {
              this.createInfo.show = false
              this.createInfo.message = null
              if (this.isEdit) {
                this.$emit('edited')
                FormUtils.clearForm(this.form)
                this.loading = false
              }
            }, 1500)
          })
          .catch((err) => {
            if (typeof err.data === 'object' && err.data !== null) {
              FormUtils.addValidationErrors(this.form, err.data)
            } else {
              this.createInfo.message = err.data || 'Произошла ошибка'
              this.createInfo.variant = 'warning'
              this.createInfo.show = true
              setTimeout(() => {
                this.createInfo.show = false
                this.createInfo.message = null
                if (this.isEdit) {
                  this.$emit('edited')
                }
              }, 3500)
            }

            this.loading = false
          })
    },
  },
  computed: {
    canCreate: function () {
      let canCreate = true
      for (let k in this.form) {
        if (this.form[k].value === null || this.form[k].value === '') {
          canCreate = false
          break
        }
        if (this.form[k].state !== null) {
          canCreate = false
          break
        }
      }
      return canCreate
    },
    phoneInvalidFeedback() {
      if (this.form.phone.value[0] !== "+" || !this.form.phone.value) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.form.phone.state = false
        return 'Формат телефона в виде +7*********'
      } else {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.form.phone.state = true
        return ''
      }
    }
  }
}
</script>

<style scoped>

</style>