<template>
  <div>
    <b-spinner v-if="loading"></b-spinner>
    <div v-if="!loading">
      <b-row>
        <h5>Создание отчёта</h5>
        <b-col sm="6">
          <b-table
              responsive
              striped
              hover
              :items="types"
              :fields="[{key: 'name', label: 'Тип отчёта'}, {key: 'count', label: 'Не отправлено событий'}]"
          ></b-table>
        </b-col>
        <b-col sm="6">
          <b-form-group
              label="Тип отчёта"
              :valid-feedback="newReport.feedback"
              :invalid-feedback="newReport.feedback"
              :state="newReport.state"
          >
            <b-input-group>
              <b-form-select
                  class="form-control"
                  v-model="newReport.type"
                  :disabled="newReport.loading"
                  :state="newReport.state"
              >
                <b-form-select-option v-for="type in types" :key="type.type" :value="type.type" class="form-control">{{ type.name }}</b-form-select-option>
              </b-form-select>
              <b-input-group-append>
                <b-button
                    v-if="!newReport.loading"
                    variant="success"
                    :disabled="!(newReport.type === 'in' || newReport.type === 'out')"
                    @click="generateNewReport"
                >Сгенерировать</b-button>
                <b-spinner v-if="newReport.loading"></b-spinner>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row style="margin-top: 1%">
        <h5>Список отчётов</h5>
        <b-row>
          <b-col sm="4">
            <b-form-group :label="loanId.label" :state="loanId.state" :invalid-feedback="loanId.feedback">
              <b-input-group>
                <b-form-input
                    :filter-type="loanId.type"
                    type="text"
                    :placeholder="loanId.placeholder"
                    v-model="loanId.value"
                    :state="loanId.state"
                    :formatter="loanId.filter"
                    @input="clearLoanIdError"
                ></b-form-input>
                <b-input-group-append>
                  <b-button-group>
                    <b-button variant="success" @click="getInfo" :disabled="loanId.state !== null">Поиск</b-button>
                    <b-button variant="warning" @click="clearLoanId">Сброс</b-button>
                  </b-button-group>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-col sm="12">
          <b-table
              responsive
              striped
              hover
              :busy="table.busy"
              :current-page="table.page"
              :items="table.items"
              :fields="table.fields"
          >
            <template #cell(action)="data">
              <b-dropdown text="Действия" variant="info" v-if="data.item.file_id !== null && data.item.completed_at !== null">
                <b-dropdown-item
                    variant="success"
                    @click="downloadReport(data.item.id)"
                >Скачать</b-dropdown-item>
                <b-dropdown-item
                  v-if="data.item.messages.length > 0"
                  @click="data.toggleDetails"
                  variant="primary"
                >{{ data.detailsShowing ? 'Скрыть' : 'Подробнее' }}</b-dropdown-item>
              </b-dropdown>
            </template>
            <template #row-details="row">
              <b-card :title="'Отчёт №' + row.item.id + ' ' + row.item.type + ' от ' + row.item.created_at">
                <div v-if="(row.item.messages || null) !== null">
                  <h5>Сообщения в Федресурс</h5>
                  <b-table
                      responsive
                      :items="row.item.messages"
                      :fields="messagesFields"
                  >
                    <template #cell(actions)="mRow">
                      <b-dropdown variant="info" text="Действия">
                        <b-dropdown-item
                            variant="success"
                            @click="downloadMessage(mRow.item.id)"
                        >Скачать</b-dropdown-item>
                        <b-dropdown-item
                            variant="primary"
                            @click="mRow.toggleDetails"
                        >{{ mRow.detailsShowing ? 'Скрыть' : 'Подробнее' }}</b-dropdown-item>
                      </b-dropdown>
                    </template>
                    <template #row-details="mRow">
                      <b-card :title="'Сообщение №' + mRow.item.id">
                        <h5>Информация о сообщении</h5>
                        <p v-if="mRow.item.error_message !== null"><strong>Ошибка:</strong> {{ mRow.item.error_message }}</p>
                        <p v-if="mRow.item.external_guid !== null"><strong>GUID Федресурса:</strong> {{ mRow.item.external_guid }}</p>
                        <p v-if="mRow.item.external_number !== null"><strong>Номер Федресурса:</strong> {{ mRow.item.external_number }}</p>
                      </b-card>
                    </template>
                  </b-table>
                </div>
              </b-card>
            </template>
          </b-table>
          <b-pagination
              pills
              v-model="table.page"
              :total-rows="table.count"
              :per-page="table.limit"
          ></b-pagination>
        </b-col>
      </b-row>
      <b-button variant="warning" @click="getInfo">Обновить</b-button>
    </div>
  </div>
</template>

<script>
import Table from "../../mixins/table";
import rpc from "../../rpc";
import FormItem from "../../mixins/form-item";
import FormUtils from "../../mixins/form-utils";

export default {
  name: "FedResurs",
  data() {
    return {
      loanId: new FormItem({
        placeholder: 'Введите ID займа',
        type: 'loanId',
        label: 'ID займа'
      }),
      messagesFields: [
        {
          key: 'id',
          label: 'Наш ID'
        },
        {
          key: 'created',
          label: 'Дата создания',
          formatter: function (value) {
            let date = new Date(value)
            let day = date.getDate()
            if (day < 10) {
              day = '0' + day
            }
            let month = date.getMonth() + 1
            if (month < 10) {
              month = '0' + month
            }
            let hours = date.getHours()
            if (hours < 10) {
              hours = '0' + hours
            }
            let minutes = date.getMinutes()
            if (minutes < 10) {
              minutes = '0' + minutes
            }
            return day + '.' + month + '.' + date.getFullYear() + ' ' + hours + ':' + minutes
          }
        },
        {
          key: 'status',
          label: 'Статус',
          formatter: function (value) {
            if (value === 'error') {
              return 'Ошибка'
            }
            if (value === 'uploaded') {
              return 'Загружено'
            }
            return value
          }
        },
        {
          key: 'actions',
          label: 'Действия'
        }
      ],
      newReport: {
        type: null,
        loading: false,
        feedback: null,
        state: null
      },
      loading: true,
      canCreate: true,
      table: new Table({
        fields: [
          {
            key: 'id',
            label: 'ID отчёта',
          },
          {
            key: 'type',
            label: 'Тип'
          },
          {
            key: 'status',
            label: 'Статус'
          },
          {
            key: 'created_at',
            label: 'Дата создания'
          },
          {
            key: 'completed_at',
            label: 'Дата завершения'
          },
          {
            key: 'action',
            label: 'Действие'
          }
        ]
      }),
      types: [],
    }
  },
  methods: {
    clearLoanId() {
      this.clearLoanIdError()
      this.loanId.value = null
      this.getInfo()
    },
    clearLoanIdError() {
      FormUtils.clearSingleValidationError(this.loanId)
    },
    getInfo() {
      this.table.busy = true
      rpc.getFedResInfo(this.table.limit, this.table.calculateOffset(), this.loanId.value).then((result) => {
        this.table.count = result.count
        this.table.items = result.data
        this.types = result.types
        this.table.busy = false
        this.loading = false
      })
    },
    generateNewReport() {
      this.newReport.loading = true
      this.table.busy = true
      rpc.createFedResReport(this.newReport.type, this.table.limit).then((result) => {
        this.table.page = 1
        this.newReport.type = null
        this.table.count = result.count
        this.table.items = result.data
        this.types = result.types
        this.table.busy = false
        this.newReport.feedback = 'Отчёт создан'
        this.newReport.state = true
        this.newReport.loading = false
        setTimeout(() => {
          this.newReport.feedback = null
          this.newReport.state = null
        }, 2000)
      }).catch((err) => {
        this.newReport.type = null
        if (err.code === -32001) {
          this.newReport.feedback = err.message
          this.newReport.state = false
          this.newReport.loading = false
          this.table.busy = false
          setTimeout(() => {
            this.newReport.feedback = null
            this.newReport.state = null
          }, 2000)
          return
        }
        this.newReport.feedback = 'Произошла ошибка'
        this.newReport.state = false
        this.newReport.loading = false
        this.table.busy = false
        setTimeout(() => {
          this.newReport.feedback = null
          this.newReport.state = null
        }, 2000)
      })
    },
    downloadReport(id) {
      window.open('/download-fedres-report/' + id, '_blank')
    },
    downloadMessage(id) {
      window.open('/download-fedres-message/' + id, '_blank')
    }
  },
  mounted() {
    this.getInfo()
  },
  watch: {
    'table.page': function () {
      this.getInfo()
    }
  }
}
</script>

<style scoped>

</style>