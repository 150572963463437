<template>
  <div>
    <b-row>
      <b-col sm="12">
        <div>
          <b-table
            striped
            hover
            responsive
            :busy="table.busy"
            :current-page="table.count"
            :items="table.items"
            :fields="table.fields"
          >
            <template #cell(actions)="row">
              <a :href="'/download-debt-sums-report/' + row.item.id" v-if="row.item.statusCode === 'completed'" target="_blank">
                <b-button variant="success">Скачать</b-button>
              </a>
            </template>
          </b-table>
          <b-pagination
            pills
            v-model="table.page"
            :total-rows="table.count"
            :per-page="table.limit"
          ></b-pagination>
        </div>
      </b-col>
    </b-row>
    <error-handler :message="error"></error-handler>
  </div>
</template>

<script>
import Table from "../../mixins/table";
import rpc from "../../rpc";
import ErrorHandler from "../ErrorHandler";

export default {
  name: "CollectorDebtSumReportTable",
  components: {ErrorHandler},
  data() {
    return {
      timeoutId: null,
      agency: null,
      error: null,
      table: new Table({
        limit: 5,
        fields: [
          {
            key: 'agency',
            label: 'Агентство'
          },
          {
            key: 'createdAt',
            label: 'Дата создания'
          },
          {
            key: 'completedAt',
            label: 'Дата завершения'
          },
          {
            key: 'status',
            label: 'Статус'
          },
          {
            key: 'dateFrom',
            label: 'Дата от'
          },
          {
            key: 'dateTo',
            label: 'Дата до'
          },
          {
            key: 'actions',
            label: 'Действие'
          }
        ],
      }),
    }
  },
  methods: {
    reset() {
      this.table.page = 1
      this.getReports()
    },
    stopRefresh() {
      if (this.timeoutId !== null) {
        clearTimeout(this.timeoutId)
        this.timeoutId = null
      }
    },
    getReports() {
      if (this.timeoutId !== null) {
        this.stopRefresh()
      } else {
        this.table.busy = true
      }
      rpc.getCollectorDebtSumReports(this.table.limit, this.table.calculateOffset(), this.agency).then((res) => {
        for (let row of res.data) {
          if (row.statusCode !== 'completed') {
            this.timeoutId = setTimeout(() => {
              this.getReports()
            }, 2000)
          }
        }
        this.table.items = res.data
        this.table.count = res.count
        this.table.busy = false
      }).catch(() => {
        this.error = 'Ошибка получения данных'
        this.table.count = 0
        this.table.items = []
        this.table.busy = false
        setTimeout(() => {
          this.error = null
        }, 2000)
      })
    }
  },
  mounted() {
    this.getReports()
  },
  destroyed() {
    if (this.timeoutId !== null) {
      clearTimeout(this.timeoutId)
    }
  },
  watch: {
    'table.page': function () {
      if (this.timeoutId !== null) {
        this.stopRefresh()
      }
      this.getReports()
    }
  }
}
</script>

<style scoped>

</style>