<template>
  <b-modal v-bind:visible="visible" title="Редактирование коллекторского агентства" no-close-on-esc hide-header-close
           hide-footer size="xl"
           v-on:change="updateModelValue"
  >
    <create-collector :init-form="initForm" is-edit @edited="edited"/>
  </b-modal>
</template>

<script>
import CreateCollector from "@/components/admin/CreateCollector.vue";
const snakeToCamel = str =>
    str.toLowerCase().replace(/([-_][a-z])/g, group =>
        group
            .toUpperCase()
            .replace('-', '')
            .replace('_', '')
    );

export default {
  name: "EditModal",
  components: {CreateCollector},
  model: {
    prop: 'visible',
    event: 'change'
  },
  props: {
    visible: Boolean,
    collector: Object
  },
  methods: {
    updateModelValue(value) {
      this.$emit('change', value)
    },
    edited() {
      this.$emit('change', false)
      this.$emit('edited')
    }
  },
  computed: {
    initForm() {
      const result = {}

      if (this.collector) {
        Object.entries(this.collector).forEach(([key, value]) => {
          if (key === 'agent_doc_date') {
            result[snakeToCamel(key)] = new Date(value).toLocaleDateString('ru-RU', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            })
          } else {
            result[snakeToCamel(key)] = value
          }
        })
      }

      return result
    }
  }
}
</script>