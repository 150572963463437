<template>
  <div>
    <b-row>
      <b-col sm="12">
        <b-card :title="'Текст сообщения №' + messageId">
          <b-spinner v-if="loading"></b-spinner>
          <b-card-body v-if="!loading" v-html="message.body"></b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <error-handler :message="errorMessage"></error-handler>
  </div>
</template>

<script>
import rpc from "../../rpc";
import ErrorHandler from "../ErrorHandler";

export default {
  name: "BorrowerMessage",
  components: {ErrorHandler},
  props: {
    messageId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      errorMessage: null,
      loading: true,
      message: {},
    }
  },
  methods: {
    getMessage() {
      this.loading = true
      rpc.getBorrowerMessage(this.messageId).then((res) => {
        this.message = res
        this.loading = false
      }).catch(() => {
        this.message.body = ''
        this.loading = false
        this.errorMessage = 'Ошибка получения текста сообщения'
        setTimeout(() => {
          this.errorMessage = null
        }, 2000)
      })
    }
  },
  mounted() {
    this.getMessage()
  }
}
</script>

<style scoped>

</style>