<template>
  <div>
    <b-row>
      <b-col sm="3">
        <b-form-group :label="loanId.label" :state="loanId.state" :invalid-feedback="loanId.feedback">
          <b-input-group>
            <b-form-input
              :filter-type="loanId.type"
              type="text"
              :placeholder="loanId.placeholder"
              v-model="loanId.value"
              :state="loanId.state"
              :formatter="loanId.filter"
              @input="clearLoanIdError"
            ></b-form-input>
            <b-input-group-append>
              <b-button variant="success" @click="getLoanHistory" :disabled="loanId.state !== null">Поиск</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-spinner v-if="loading"></b-spinner>
      <div v-if="!loading">
        <b-col sm="12">
          <b-table
            responsive
            striped
            hover
            :busy="table.busy"
            :current-page="table.page"
            :items="table.items"
            :fields="table.fields"
          >
            <template #cell(fio)="data">
              {{ data.item.surname + ' ' + data.item.name + ' ' + data.item.patronymic }}
            </template>
            <template #cell(action)="row">
              <b-button variant="info" @click="row.toggleDetails">{{ row.detailsShowing ? 'Скрыть' : 'Подробнее' }}</b-button>
            </template>
            <template #row-details="row">
              <b-card>
                <p><strong>UUID заёмщика:</strong> {{ row.item.borrowerUuid }}</p>
                <p><strong>Дата создания записи:</strong> {{ row.item.created }}</p>
                <p><strong>Дата выдачи:</strong> {{ row.item.issueDate }}</p>
                <p><strong>Дата планового завершения:</strong> {{ row.item.plannedEndDate }}</p>
                <p><strong>Телефон:</strong> {{ row.item.phone }}</p>
              </b-card>
            </template>
          </b-table>
          <b-pagination
            pills
            v-model="table.page"
            :total-rows="table.count"
            :per-page="table.limit"
          ></b-pagination>
        </b-col>
      </div>
    </b-row>
    <error-handler :message="errorMessage"></error-handler>
  </div>
</template>

<script>
import FormItem from "../../mixins/form-item";
import Table from "../../mixins/table";
import FormUtils from "../../mixins/form-utils";
import ErrorHandler from "../ErrorHandler";
import rpc from "../../rpc";

export default {
  name: "LoanHistory",
  components: {ErrorHandler},
  data() {
    return {
      errorMessage: null,
      loanId: new FormItem({
        placeholder: 'Введите ID займа',
        type: 'loanId',
        label: 'ID займа'
      }),
      table: new Table({
        fields: [
          {
            key: 'loanId',
            label: 'Номер займа'
          },
          {
            key: 'status',
            label: 'Статус'
          },
          {
            key: 'collectorName',
            label: 'Коллектор'
          },
          {
            key: 'collectorInDate',
            label: 'Дата назначения коллектору'
          },
          {
            key: 'collectorOutDate',
            label: 'Дата снятия с коллектора'
          },
          {
            key: 'fio',
            label: 'ФИО',
          },
          {
            key: 'action',
            label: 'Действия'
          }
        ],
      }),
      loading: false,
    }
  },
  methods: {
    clearLoanIdError() {
      FormUtils.clearSingleValidationError(this.loanId)
    },
    getLoanHistory() {
      if (this.loanId.state === null) {
        this.loading = true
        this.table.items = []
        this.table.count = 0
        this.getHistoryTable()
      }
    },
    getHistoryTable() {
      this.table.busy = true
      rpc.getLoanHistory(this.loanId.value, this.table.limit, this.table.calculateOffset()).then((res) => {
        this.table.count = res.count
        this.table.items = res.data
        this.table.busy = false
        this.loading = false
      }).catch((err) => {
        if (typeof err.data === 'object' && err.code === -32602) {
          FormUtils.addValidationErrors({loanId: this.loanId}, err.data)
          setTimeout(() => {
            this.clearLoanIdError()
          }, 2000)
        } else {
          this.errorMessage = 'Произошла ошибка'
          setTimeout(() => {
            this.errorMessage = null
          }, 2000)
        }
        this.table.busy = false
        this.loading = false
      })
    }
  },
  watch: {
    'table.page': function () {
      this.getHistoryTable()
    }
  }
}
</script>

<style scoped>

</style>