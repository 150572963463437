<template>
  <div>
    <b-button-group>
      <b-button variant="primary" v-if="loans.id !== null" @click="downloadReport(loans.id)">
        Список займов от {{ loans.completed }}
      </b-button>
      <b-button variant="info" v-if="payments.id !== null" @click="downloadReport(payments.id)">
        Реестр платежей от {{ payments.completed }}
      </b-button>
    </b-button-group>
    <error-handler :message="errorMessage"></error-handler>
  </div>
</template>

<script>
import CollectorReport from "../../mixins/collector-report";
import rpc from "../../rpc";
import ErrorHandler from "../ErrorHandler";

export default {
  name: "Reports",
  components: {ErrorHandler},
  data() {
    return {
      errorMessage: null,
      loans: new CollectorReport(),
      payments: new CollectorReport(),
    }
  },
  methods: {
    getReports() {
      rpc.getLastCollectorReports().then((result) => {
        this.loans = new CollectorReport(result.loans || {})
        this.payments = new CollectorReport(result.payments || {})
      }).catch(() => {
        this.errorMessage = 'Ошибка получения выгрузок'
        setTimeout(() => {
          this.errorMessage = null
        }, 2000)
      })
    },
    downloadReport(id) {
      window.open('/download-collector-report/' + id, '_blank')
    }
  },
  mounted() {
    this.getReports()
  }
}
</script>

<style scoped>

</style>