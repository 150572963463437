<template>
  <div>
    <b-tabs justified pills>
      <b-tab title="История займа" lazy>
        <h4 style="text-align: center; margin-top: 1%">История пребывания займа у внешних коллекторов</h4>
        <loan-history></loan-history>
      </b-tab>
      <b-tab title="Займы без ИНН" lazy>
        <h4 style="text-align: center; margin-top: 1%">Список займов без ИНН</h4>
        <no-inn-loans></no-inn-loans>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import NoInnLoans from "./NoInnLoans";
import LoanHistory from "./LoanHistory";
export default {
  name: "Loans",
  components: {LoanHistory, NoInnLoans}
}
</script>

<style scoped>

</style>