<template>
  <div>
    <b-row>
      <b-col sm="2">
        <b-form-group label="Выводить по">
          <b-select class="form-control" v-model="table.limit">
            <b-select-option class="form-control" v-for="l in table.limits" :value="l" :key="l">{{ l }}</b-select-option>
          </b-select>
        </b-form-group>
      </b-col>
      <b-col sm="2">
        <b-form-group label="Статус">
          <b-select class="form-control" v-model="search.status">
            <b-select-option value="sent">Отправлено</b-select-option>
            <b-select-option value="get_data_error">Ошибка получения данных</b-select-option>
            <b-select-option value="create_error">Ошибка формирования</b-select-option>
            <b-select-option value="send_error">Ошибка отправки</b-select-option>
          </b-select>
        </b-form-group>
      </b-col>
      <b-col sm="2">
        <b-form-group :label="search.borrowerUuid.label">
          <b-form-input
            type="text"
            :filter-type="search.borrowerUuid.type"
            :placeholder="search.borrowerUuid.placeholder"
            v-model="search.borrowerUuid.value"
            :formatter="search.borrowerUuid.filter"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col sm="2">
        <b-form-group :label="search.loanId.label">
          <b-form-input
            type="text"
            :filter-type="search.loanId.type"
            :placeholder="search.loanId.placeholder"
            v-model="search.loanId.value"
            :formatter="search.loanId.filter"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row style="margin-top: 1%">
      <b-col sm="3">
        <b-button-group>
          <b-button variant="success" @click="runSearch">Поиск</b-button>
          <b-button variant="warning" @click="clearSearch">Сброс</b-button>
        </b-button-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12">
        <div>
          <b-table
            striped
            hover
            responsive
            :busy="table.busy"
            :current-page="table.page"
            :items="table.items"
            :fields="table.fields"
          >
            <template #cell(actions)="row">
              <b-button v-if="row.item.hasText && row.item.statusCode === 'sent'" variant="info" @click="row.toggleDetails">{{ row.detailShowing ? 'Скрыть' : 'Прочитать' }}</b-button>
              <b-button v-if="row.item.hasText && row.item.statusCode === 'send_error'" variant="primary" @click="repeatBorrowerMessage(row.item.id)">Повторить</b-button>
              <span v-if="!row.item.hasText">Нет тела сообщения</span>
            </template>
            <template #row-details="row">
              <borrower-message :message-id="row.item.id"></borrower-message>
            </template>
          </b-table>
          <b-pagination
              pills
              v-model="table.page"
              :total-rows="table.count"
              :per-page="table.limit"
          ></b-pagination>
        </div>
      </b-col>
    </b-row>
    <error-handler :message="errorMessage"></error-handler>
  </div>
</template>

<script>
import Table from "../../mixins/table";
import rpc from "../../rpc";
import FormItem from "../../mixins/form-item";
import ErrorHandler from "../ErrorHandler";
import BorrowerMessage from "./BorrowerMessage";

export default {
  name: "BorrowerMessages",
  components: {BorrowerMessage, ErrorHandler},
  data() {
    return {
      table: new Table({
        fields: [
          {
            key: 'id',
            label: 'ID'
          },
          {
            key: 'status',
            label: 'Статус'
          },
          {
            key: 'createdAt',
            label: 'Дата создания'
          },
          {
            key: 'sendDate',
            label: 'Дата отправки'
          },
          {
            key: 'borrowerUuid',
            label: 'UUID заёмщика'
          },
          {
            key: 'loanId',
            label: 'ID займа'
          },
          {
            key: 'collector',
            label: 'Коллектор',
          },
          {
            key: 'actions',
            label: 'Действия'
          }
        ],
      }),
      search: {
        status: null,
        borrowerUuid: new FormItem({placeholder: 'Введите UUID заёмщика', label: 'UUID заёмщика', type: 'uuid'}),
        loanId: new FormItem({placeholder: 'Введите ID займа', label: 'ID займа', type: 'loanId'}),
      },
      errorMessage: null
    }
  },
  methods: {
    repeatBorrowerMessage(id) {
      this.table.busy = true
      rpc.repeatBorrowerMessage(id).then(() => {
        this.getMessages()
      }).catch(() => {
        this.errorMessage = 'Произошла ошибка переотправки сообщения заёмщику'
        setTimeout(() => {
          this.errorMessage = null
        }, 3000)
        this.getMessages()
      })
    },
    clearSearch() {
      this.search.status = null
      this.search.loanId.value = null
      this.search.borrowerUuid.value = null
      this.runSearch()
    },
    runSearch() {
      this.table.busy = true
      this.table.page = 1
      this.getMessages()
    },
    getMessages() {
      this.table.busy = true
      rpc.getBorrowerMessages(
          this.table.limit,
          this.table.calculateOffset(),
          this.search.status,
          this.search.borrowerUuid.value,
          this.search.loanId.value
      ).then((res) => {
        this.table.items = res.data
        this.table.count = res.count
        this.table.busy = false
      }).catch(() => {
        this.errorMessage = 'Ошибка получения данных'
        this.table.count = 0
        this.table.items = []
        this.table.busy = false
        setTimeout(() => {
          this.errorMessage = null
        }, 3000)
      })
    }
  },
  mounted() {
    this.getMessages()
  },
  watch: {
    'table.page': function () {
      this.getMessages()
    },
    'table.limit': function () {
      this.getMessages()
    }
  }
}
</script>

<style scoped>

</style>