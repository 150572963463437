<template>
  <div>
    <b-tabs justified pills>
      <b-tab title="Отчёты" lazy>
        <h4>Список и создание отчётов в Федресурс</h4>
        <fed-resurs></fed-resurs>
      </b-tab>
      <b-tab title="Сообщения с ошибками" lazy>
        <h4>Сообщения с ошибками отправки в Федресурс</h4>
        <p>Переотправлять стоит те сообщения, в ошибке которых нет смыслового описания ошибки.</p>
        <p>Например, сообщение с ошибкой "Внутренняя ошибка" или "Ошибка переотправки" можно безболезненно перезапустить.</p>
        <p>А сообщение, в котором есть что-то наподобие "Содержимое элемента является неполным" и т.п. лучше не перезапускать, тут требуется немного править код.</p>
        <fed-resurs-message-errors></fed-resurs-message-errors>
      </b-tab>
      <b-tab title="События с ошибками" lazy>
        <h4>События с ошибкой формирования отчёта в Федресурс</h4>
        <fed-resurs-errors></fed-resurs-errors>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import FedResurs from "./FedResurs";
import FedResursErrors from "./FedResursErrors";
import FedResursMessageErrors from "./FedResursMessageErrors";
export default {
  name: "FedResursMain",
  components: {FedResursMessageErrors, FedResursErrors, FedResurs}
}
</script>

<style scoped>

</style>