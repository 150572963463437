<template>
  <div>
    <b-spinner v-if="loading"></b-spinner>
    <div v-if="!loading">
      <b-table
        responsive
        striped
        hover
        :busy="table.busy"
        :current-page="table.page"
        :items="table.items"
        :fields="table.fields"
      ></b-table>
      <b-pagination
        pills
        v-model="table.page"
        :total-rows="table.count"
        :per-page="table.limit"
      ></b-pagination>
    </div>
    <error-handler :message="errorMessage"></error-handler>
  </div>
</template>

<script>
import Table from "../../mixins/table";
import rpc from "../../rpc";
import ErrorHandler from "../ErrorHandler";

export default {
  name: "NoInnLoans",
  components: {ErrorHandler},
  data() {
    return {
      loading: true,
      table: new Table({
        fields: [
          {
            key: 'loanId',
            label: 'Номер займа'
          },
          {
            key: 'issueDate',
            label: 'Дата выдачи'
          },
          {
            key: 'plannedEndDate',
            label: 'Дата планового завершения'
          },
          {
            key: 'surname',
            label: 'Фамилия'
          },
          {
            key: 'name',
            label: 'Имя'
          },
          {
            key: 'patronymic',
            label: 'Отчество'
          },
          {
            key: 'phone',
            label: 'Телефон'
          }
        ],
      }),
      errorMessage: null
    }
  },
  methods: {
    getNoInnLoans() {
      this.table.busy = true
      rpc.getNoInnLoans(this.table.limit, this.table.calculateOffset()).then((res) => {
        this.table.count = res.count
        this.table.items = res.data
        this.table.busy = false
        this.loading = false
      }).catch(() => {
        this.errorMessage = 'Произошла ошибка'
        setTimeout(() => {
          this.errorMessage = null
        }, 2000)
      })
    }
  },
  mounted() {
    this.getNoInnLoans()
  },
  watch: {
    'table.page': function () {
      this.getNoInnLoans()
    }
  }
}
</script>

<style scoped>

</style>