import { render, staticRenderFns } from "./BorrowerMessage.vue?vue&type=template&id=6cf67d56&scoped=true&"
import script from "./BorrowerMessage.vue?vue&type=script&lang=js&"
export * from "./BorrowerMessage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cf67d56",
  null
  
)

export default component.exports