<template>
  <b-modal
      ref="errorModal"
      header-bg-variant="danger"
      hide-footer
      no-close-on-esc no-close-on-backdrop
      hide-header-close
  >
    {{ message }}
  </b-modal>
</template>

<script>
export default {
  name: "ErrorHandler",
  props: {
    message: {
      required: true
    }
  },
  watch: {
    message: function () {
      this.message === null ? this.$refs.errorModal.hide() : this.$refs.errorModal.show()
    }
  }
}
</script>

<style scoped>

</style>