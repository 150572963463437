<template>
  <div>
    <b-tabs justified pills>
      <b-tab title="Список" lazy>
        <h4>Список коллекторских агентств</h4>
        <collectors-table style="margin-top: 1%"></collectors-table>
      </b-tab>
      <b-tab title="Создание" lazy>
        <h4>Создание нового коллекторского агентства</h4>
        <create-collector></create-collector>
      </b-tab>
      <b-tab title="Выгрузки" lazy>
        <h4>Коллекторские выгрузки</h4>
        <collector-reports></collector-reports>
      </b-tab>
      <b-tab title="Выгрузки по платежам" lazy>
        <h4>Выгрузки по платежам</h4>
        <collector-payment-report></collector-payment-report>
      </b-tab>
      <b-tab title="Выгрузки по суммам" lazy>
        <h4>Выгрузки по переданным суммам</h4>
        <collector-debt-sum-report></collector-debt-sum-report>
      </b-tab>
      <b-tab title="Сообщения" lazy>
        <h4>Список сообщений заёмщикам</h4>
        <borrower-messages></borrower-messages>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import CollectorsTable from "./CollectorsTable";
import CreateCollector from "./CreateCollector";
import CollectorReports from "./CollectorReports";
import BorrowerMessages from "./BorrowerMessages";
import CollectorPaymentReport from "./CollectorPaymentReport";
import CollectorDebtSumReport from "@/components/admin/CollectorDebtSumReport";
export default {
  name: "Collectors",
  components: {
    CollectorDebtSumReport,
    CollectorPaymentReport, BorrowerMessages, CollectorReports, CreateCollector, CollectorsTable},
}
</script>

<style scoped>

</style>