export default class Filters {
    static dateFilter(val) {
        if (val === '' || val === null) {
            return null
        }
        val = val.replace(/[^0-9.]+/g, '')
        val = val.trim()
        if (val.length > 10) {
            val = val.substring(0, 10)
        }
        if (val.length === 10) {
            val = val.match(/^(\d{1,2}).(\d{1,2}).(\d{4})$/)
            val = (val) ? val[1] + '.' + val[2] + '.' + val[3] : null
        }
        return val
    }

    static genderFilter(val) {
        if (val === '' || val === null) {
            return null
        }
        val = val.replace(/[^mf]+/g, '')
        val = val.trim()
        if (val.length > 1) {
            val = val.substring(0, 1)
        }
        return val
    }

    static ogrnInnFilter(val, length) {
        if (val === '' || val === null) {
            return null
        }
        val = val.replace(/[^0-9]+/g, '')
        val = val.trim()
        if (val.length > length) {
            val = val.substring(0, length)
        }
        return val
    }

    static loanIdFilter(val) {
        if (val === '' || val === null) {
            return null
        }
        val = val.replace(/[^0-9]+/g, '')
        val = val.trim()
        return val
    }

    static addressFilter(val) {
        if (val === '' || val === null) {
            return null
        }
        val = val.replace(/[^а-яА-Я,.0-9 ]+/g, '')
        val = val.trim()
        return val
    }

    static loginPasswordFilter(val) {
        if (val === '' || val === null) {
            return null
        }
        val = val.replace(/[^a-zA-Z0-9\-_.!#@&*]+/g, '')
        val = val.trim()
        return val
    }

    static nameFilter(val) {
        if (val === '' || val === null) {
            return null
        }
        val = val.replace(/[^a-zA-Zа-яА-ЯёЁ0-9" ]+/g, '')
        return val
    }

    static privateNameFilter(val) {
        if (val === '' || val === null) {
            return null
        }
        val = val.replace(/[^а-яА-ЯёЁ -]+/g, '')
        return val
    }

    static emailFilter(val) {
        if (val === '' || val === null) {
            return null
        }
        val = val.replace(/[^a-zA-Z0-9!#$%&'*+\-/=?^_`{|}~.@]+/g, '')
        val = val.trim()
        return val
    }

    static phoneFilter(val) {
        if (val === '' || val === null) {
            return ''
        }
        if (val.length === 1 && val !== '+') {
            return ''
        }
        if (val.length > 1 && val.slice(-1) === '+') {
            val = val.substring(0, val.length - 1)
        }
        val = val.replace(/[^+0-9]+/g, '')
        if (val.length > 12) {
            val = val.substring(0, 12)
        }
        return val
    }

    static numberViewFormat(val, digits = 3) {
        if (typeof val === 'string') {
            return parseFloat(val).toFixed(digits)
        }
        if (typeof val === 'number') {
            return val.toFixed(digits)
        }
        return val
    }
}