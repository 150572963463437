import { render, staticRenderFns } from "./BorrowerMessages.vue?vue&type=template&id=0d27db02&scoped=true&"
import script from "./BorrowerMessages.vue?vue&type=script&lang=js&"
export * from "./BorrowerMessages.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d27db02",
  null
  
)

export default component.exports