export default class Table {
    busy
    count
    items
    fields
    page
    limits
    limit
    sort = {
        by: null,
        direction: null
    }

    constructor(data = {}) {
        this.busy = data.busy || false
        this.count = data.count || 0
        this.items = data.items || []
        this.fields = data.fields || []
        this.page = data.page || 1
        this.limits = data.limits || [10, 50, 100]
        this.limit = data.limit || 10
        this.sort = data.sort || {by: null, direction: null}
    }

    addItems(items = []) {
        this.items = items
    }

    calculateOffset() {
        return (this.page - 1) * this.limit
    }

    getSortBy() {
        let sort = this.sort.by
        if (typeof sort === 'string') {
            sort = sort.trim()
        }
        return (sort === null || sort === '') ? null : sort
    }

    getDirection() {
        return this.sort.direction ? 'DESC' : 'ASC'
    }
}