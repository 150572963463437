<template>
  <div>
    <div v-if="$store.user.isAdmin">
      <admin-main></admin-main>
    </div>
    <div v-if="!$store.user.isAdmin">
      <collector-main></collector-main>
    </div>
  </div>
</template>

<script>
import AdminMain from "./AdminMain";
import CollectorMain from "./CollectorMain";

export default {
  name: "Main",
  components: {CollectorMain, AdminMain},
}
</script>

<style scoped>

</style>