<template>
  <div>
    <b-row style="margin-top: 1%">
      <b-col sm="1">
        <b-form-group label="Выводить по">
          <b-form-select v-model="table.limit" class="form-control">
            <b-form-select-option v-for="l in table.limits" :key="l" :value="l">{{ l }}</b-form-select-option>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col v-for="s in search" :key="s.code" :sm="s.sm">
        <b-form-group :label="s.name" :state="s.state" :invalid-feedback="s.feedback">
          <b-form-input
              :type="s.type"
              :filter-type="s.code"
              :placeholder="s.placeholder === null ? s.name : s.placeholder"
              v-model="s.value"
              autocomplete="false"
              :formatter="s.filter"
              :state="s.state"
              @input="s.clearError()"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row style="margin-top: 1%">
      <b-col sm="2">
        <b-button-group>
          <b-button variant="success" @click="processSearch">Поиск</b-button>
          <b-button variant="warning" @click="clearSearch">Сброс</b-button>
        </b-button-group>
      </b-col>
      <b-col sm="4">
        <reports></reports>
      </b-col>
    </b-row>
    <b-row style="margin-top: 1%">
      <b-table
          responsive
          striped
          hover
          :busy="table.busy"
          :current-page="table.page"
          :items="table.items"
          :fields="table.fields"
          :sort-by.sync="table.sort.by"
          :sort-desc.sync="table.sort.direction"
          label-sort-asc=""
          label-sort-desc=""
          label-sort-clear=""
          no-local-sorting
      >
        <template #cell(loanId)="data">
          <router-link :to="'/loan/' + data.item.id">{{ data.item.loanId }}</router-link>
        </template>
        <template #cell(currentSum)="data">
          {{ data.value | numberFormat }}
        </template>
      </b-table>
      <p>Всего займов: <strong>{{ table.count }}</strong></p>
      <b-pagination
        :disabled="table.busy"
        pills
        v-model="table.page"
        :total-rows="table.count"
        :per-page="table.limit"
      ></b-pagination>
    </b-row>
  </div>
</template>

<script>
import rpc from "../../rpc";
import Table from "../../mixins/table";
import SearchField from "../../mixins/search-field";
import Filters from "../../mixins/filters";
import AuthErrorHandler from "../../mixins/auth-error-handler";
import Reports from "./Reports";

export default {
  name: "LoansTable",
  components: {Reports},
  data() {
    return {
      search: [
        new SearchField({name: 'Фамилия', code: 'surname', sm: 2, placeholder: 'Введите фамилию'}),
        new SearchField({name: 'Имя', code: 'name', sm: 2, placeholder: 'Введите имя'}),
        new SearchField({name: 'Отчество', code: 'patronymic', sm:2, placeholder: 'Введите отчество'}),
        new SearchField({name: 'Номер займа', code: 'loanId', sm: 2, type: 'number', placeholder: 'Введите номер займа'}),
        new SearchField({name: 'Телефон', code: 'phone', sm:2, placeholder: 'Телефон (+7ХХХХХХХХХХ)'}),
      ],
      table: new Table({
        sort: {
          by: 'collectorInDate',
          direction: true
        },
        limit: 10,
        limits: [10, 25, 50, 75, 100],
        fields: [
          {
            key: 'loanId',
            label: 'Номер займа'
          },
          {
            key: 'fio',
            label: 'ФИО заёмщика'
          },
          {
            key: 'currentSum',
            label: 'Сумма текущей задолженности'
          },
          {
            key: 'delayInDays',
            label: 'Количество дней просрочки'
          },
          {
            key: 'collectorInDate',
            label: 'Дата попадания к коллектору',
            sortable: true
          }
        ]
      })
    }
  },
  methods: {
    clearSearch() {
      for (let k in this.search) {
        this.search[k].value = null
        this.search[k].state = null
        this.search[k].feedback = null
      }
      this.getTable()
    },
    processSearch() {
      this.table.busy = true
      this.getTable()
    },
    getTable() {
      this.table.busy = true
      let request = {}
      for (let s in this.search) {
        let value = this.search[s].value
        if (typeof value === 'string') {
          value = value.trim()
          if (parseFloat(value) && this.search[s].code !== 'phone') {
            value = +value
          }
        }
        if (value === '') {
          value = null
        }
        request[this.search[s].code] = value
      }
      request['offset'] = this.table.calculateOffset()
      request['limit'] = this.table.limit
      request['sort'] = {
        field: this.table.getSortBy(),
        direction: this.table.getDirection(),
      }
      rpc.getLoansTable(request).then((result) => {
        this.table.items = result.data
        this.table.count = result.count
        this.table.busy = false
      }).catch((err) => {
        if (err.code === -32602 && Array.isArray(err.data)) {
          let triggered = false
          for (let i in err.data) {
            let search = this.getSearchWithCode(err.data[i].field)
            if (typeof search === 'object' && search !== null) {
              search.feedback = err.data[i].message
              search.state = false
              triggered = true
            }
          }
          if (!triggered) {
            this.table.items = []
            this.table.count = 0
            this.table.busy = false
          }
        } else {
          AuthErrorHandler.handleAuthError(err)
          this.table.items = []
          this.table.count = 0
          this.table.busy = false
        }
      })
    },
    getSearchWithCode(code) {
      for (let i in this.search) {
        if (this.search[i].code === code) {
          return this.search[i]
        }
      }
      return null
    }
  },
  watch: {
    'table.page': function () {
      this.getTable()
    },
    'table.limit': function () {
      this.getTable()
    },
    'table.sort.by': function () {
      this.getTable()
    },
    'table.sort.direction': function () {
      this.getTable()
    }
  },
  mounted() {
    this.getTable()
  },
  filters: {
    numberFormat: function (val) {
      return Filters.numberViewFormat(val, 2)
    }
  }
}
</script>

<style scoped>

</style>