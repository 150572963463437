import { render, staticRenderFns } from "./FedResursErrors.vue?vue&type=template&id=9af60772&scoped=true&"
import script from "./FedResursErrors.vue?vue&type=script&lang=js&"
export * from "./FedResursErrors.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9af60772",
  null
  
)

export default component.exports