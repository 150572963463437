<template>
  <apexchart type="donut" :series="data.series" :options="fullChartOptions"></apexchart>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import ChartModel from "../mixins/chart-model";

export default {
  name: "Chart",
  components: {
    'apexchart': VueApexCharts
  },
  props: {
    data: {
      type: ChartModel,
      required: true,
    }
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'donut',
        },
        responsive: [{
          breakpoint: 900000,
          options: {
            chart: {
              width: '100%',
            },
            legend: {
              show: true,
              position: 'bottom'
            }
          }
        }]
      },
    }
  },
  computed: {
    fullChartOptions: function () {
      let options = this.chartOptions
      options.labels = this.data.labels || []
      return options
    }
  },
  mounted() {
    window.dispatchEvent(new Event('resize'))
  }
}
</script>

<style scoped>

</style>