<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col sm="12">
          <b-card title="Займы">
            <b-card-text>
              <loans-table></loans-table>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import LoansTable from "../components/collector/LoansTable";
export default {
  name: "CollectorMain",
  components: {LoansTable},
}
</script>

<style scoped>

</style>