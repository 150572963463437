<template>
  <div>
    <b-row>
      <h5>Создание администратора</h5>
      <b-col sm="4">
        <b-form-group
          :label="newAdmin.login.label"
          :state="newAdmin.login.state"
          :invalid-feedback="newAdmin.login.feedback"
        >
          <b-form-input
              :filter-type="newAdmin.login.type"
              type="text"
              :placeholder="newAdmin.login.placeholder"
              v-model="newAdmin.login.value"
              :state="newAdmin.login.state"
              :formatter="newAdmin.login.filter"
              :disabled="creating"
              @input="newAdmin.login.state = null; newAdmin.login.feedback = null"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col sm="4">
        <b-form-group
            :label="newAdmin.password.label"
            :state="newAdmin.password.state"
            :invalid-feedback="newAdmin.password.feedback"
        >
          <b-form-input
              :filter-type="newAdmin.password.type"
              type="password"
              :placeholder="newAdmin.password.placeholder"
              v-model="newAdmin.password.value"
              :state="newAdmin.password.state"
              :formatter="newAdmin.password.filter"
              :disabled="creating"
              @input="newAdmin.password.state = null; newAdmin.password.feedback = null"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col sm="4">
        <b-form-group
            :label="newAdmin.adminPass.label"
            :state="newAdmin.adminPass.state"
            :invalid-feedback="newAdmin.adminPass.feedback"
        >
          <b-form-input
              :filter-type="newAdmin.adminPass.type"
              type="password"
              :placeholder="newAdmin.adminPass.placeholder"
              v-model="newAdmin.adminPass.value"
              :state="newAdmin.adminPass.state"
              :formatter="newAdmin.adminPass.filter"
              :disabled="creating"
              @input="newAdmin.adminPass.state = null; newAdmin.adminPass.feedback = null"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row style="margin-top: 1%">
      <b-col sm="4">
        <b-spinner v-if="creating"></b-spinner>
        <b-button v-if="!creating" variant="success" @click="createAdmin">Создать</b-button>
      </b-col>
    </b-row>
    <b-row style="margin-top: 1%">
      <h5>Список администраторов</h5>
      <b-col sm="12">
        <b-table
          responsive
          striped
          hover
          :busy="table.busy"
          :current-page="table.page"
          :items="table.items"
          :fields="table.fields"
        >
          <template #cell(actions)="data">
            <b-button-group>
              <b-button variant="warning" @click="startChangeAdminPassword(data.item)">Изменить пароль</b-button>
              <b-button variant="danger" @click="startChangeAdminActivity(data.item)">
                {{ data.item.active ? 'Деактивировать' : 'Активировать' }}
              </b-button>
            </b-button-group>
          </template>
        </b-table>
        <b-pagination
            pills
            v-model="table.page"
            :total-rows="table.count"
            :per-page="table.limit"
        ></b-pagination>
      </b-col>
    </b-row>
    <b-modal
      ref="changePasswordModal"
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
      :title="'Изменение пароля администратора ' + changingPassword.login"
  >
    <b-spinner v-if="changingPassword.loading"></b-spinner>
    <div v-if="!changingPassword.loading && changingPassword.result !== null">
      <p><strong>Логин: </strong> {{ changingPassword.result.login }}</p>
      <p><strong>Пароль: </strong> {{ changingPassword.result.password }}</p>
    </div>
    <div v-if="!changingPassword.loading && changingPassword.result === null">
      <b-form-group
          :label="changingPassword.adminPass.label"
          :state="changingPassword.adminPass.state"
          :invalid-feedback="changingPassword.adminPass.feedback"
      >
        <b-form-input
            :filter-type="changingPassword.adminPass.type"
            type="password"
            :placeholder="changingPassword.adminPass.placeholder"
            v-model="changingPassword.adminPass.value"
            :state="changingPassword.adminPass.state"
            :formatter="changingPassword.adminPass.filter"
            :disabled="creating"
            @input="changingPassword.adminPass.state = null; changingPassword.adminPass.feedback = null"
        ></b-form-input>
      </b-form-group>
      <p class="text-danger" v-if="changingPassword.userId.state !== null">{{ changingPassword.userId.feedback }}</p>
    </div>
    <template #modal-footer style="margin-top: 1%">
      <div v-if="changingPassword.result === null">
        <b-button variant="success" @click="processChangeAdminPassword">Подтвердить</b-button>
        <b-button variant="warning" @click="cancelChangeAdminPassword">Отмена</b-button>
      </div>
      <div v-if="changingPassword.result !== null">
        <b-button variant="success" @click="cancelChangeAdminPassword">Закрыть</b-button>
      </div>
    </template>
  </b-modal>
  <b-modal
      ref="changeAdminActivityModal"
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
      :title="'Изменение активности администратора ' + changeActivity.login"
  >
    <b-spinner v-if="changeActivity.loading"></b-spinner>
    <div v-if="!changeActivity.loading">
      <b-form-group
          :label="changeActivity.adminPass.label"
          :state="changeActivity.adminPass.state"
          :invalid-feedback="changeActivity.adminPass.feedback"
      >
        <b-form-input
            :filter-type="changeActivity.adminPass.type"
            type="password"
            :placeholder="changeActivity.adminPass.placeholder"
            v-model="changeActivity.adminPass.value"
            :state="changeActivity.adminPass.state"
            :formatter="changeActivity.adminPass.filter"
            :disabled="creating"
            @input="changeActivity.adminPass.state = null; changeActivity.adminPass.feedback = null"
        ></b-form-input>
      </b-form-group>
      <p class="text-danger" v-if="changeActivity.userId.state !== null">{{ changeActivity.userId.feedback }}</p>
    </div>
    <template #modal-footer style="margin-top: 1%">
      <b-button variant="success" @click="processChangeAdminActivity">Подтвердить</b-button>
      <b-button variant="warning" @click="cancelChangeAdminActivity">Отмена</b-button>
    </template>
  </b-modal>
  </div>
</template>

<script>
import Table from "../../mixins/table";
import FormItem from "../../mixins/form-item";
import rpc from "../../rpc";
import FormUtils from "../../mixins/form-utils";

export default {
  name: "Administrators",
  data() {
    return {
      changeActivity: {
        loading: false,
        userId: new FormItem(),
        login: null,
        adminPass: new FormItem({type: 'password', placeholder: 'Введите административный пароль', label: 'Административный пароль'}),
      },
      changingPassword: {
        loading: false,
        userId: new FormItem(),
        login: null,
        adminPass: new FormItem({type: 'password', placeholder: 'Введите административный пароль', label: 'Административный пароль'}),
        result: null,
      },
      creating: false,
      table: new Table({
        busy: true,
        fields: [
          {
            key: 'id',
            label: 'ID'
          },
          {
            key: 'login',
            label: 'Логин'
          },
          {
            key: 'created',
            label: 'Дата создания'
          },
          {
            key: 'active',
            label: 'Статус',
            formatter: function (value) {
              return value ? 'Активен' : 'Деактивирован'
            }
          },
          {
            key: 'actions',
            label: 'Действия'
          }
        ]
      }),
      newAdmin: {
        login: new FormItem({type: 'login', placeholder: 'Введите логин', label: 'Логин'}),
        password: new FormItem({type: 'password', placeholder: 'Введите пароль', label: 'Пароль'}),
        adminPass: new FormItem({type: 'password', placeholder: 'Введите административный пароль', label: 'Административный пароль'})
      }
    }
  },
  methods: {
    createAdmin() {
      if (this.newAdmin.login.state === null && this.newAdmin.password.state === null) {
        this.creating = true
        this.table.busy = true
        rpc.createAdmin(this.newAdmin.login.value, this.newAdmin.password.value, this.newAdmin.adminPass.value, this.table.limit).then((result) => {
          this.table.page = 1
          this.table.items = result.data
          this.table.count = result.count
          this.newAdmin.login.value = null
          this.newAdmin.password.value = null
          this.table.busy = false
          this.creating = false
        }).catch((err) => {
          if (typeof err === 'object' && err.code === -32602) {
            FormUtils.addValidationErrors(this.newAdmin, err.data)
          }
          this.table.busy = false
          this.creating = false
        })
      }
    },
    getAdmins() {
      this.table.busy = true
      rpc.getAdmins(this.table.limit, this.table.calculateOffset()).then((result) => {
        this.table.items = result.data
        this.table.count = result.count
        this.table.busy = false
      })
    },
    startChangeAdminPassword(item) {
      this.changingPassword.userId.value = item.id
      this.changingPassword.login = item.login
      this.$refs.changePasswordModal.show()
    },
    processChangeAdminPassword() {
      this.changingPassword.loading = true
      rpc.resetAdminPassword(this.changingPassword.userId.value, this.changingPassword.adminPass.value).then((res) => {
        this.changingPassword.result = res
        this.changingPassword.loading = false
      }).catch((err) => {
        if (typeof err === 'object' && err.code === -32602) {
          FormUtils.addValidationErrors(this.changingPassword, err.data)
          this.changingPassword.loading = false
        } else {
          this.changingPassword.userId.state = false
          this.changingPassword.userId.feedback = 'Произошла ошибка'
          this.changingPassword.loading = false
        }
      })
    },
    cancelChangeAdminPassword() {
      this.$refs.changePasswordModal.hide()
      this.changingPassword.userId.value = null
      this.changingPassword.userId.feedback = null
      this.changingPassword.userId.state = null
      this.changingPassword.login = null
      this.changingPassword.result = null
      this.changingPassword.adminPass.value = null
      this.changingPassword.adminPass.feedback = null
      this.changingPassword.adminPass.state = null
    },
    startChangeAdminActivity(item) {
      this.changeActivity.userId.value = item.id
      this.changeActivity.login = item.login
      this.$refs.changeAdminActivityModal.show()
    },
    processChangeAdminActivity() {
      this.table.busy = true
      this.changeActivity.loading = true
      rpc.changeAdminActivity(
          this.changeActivity.userId.value,
          this.changeActivity.adminPass.value,
          this.table.limit,
          this.table.calculateOffset()
      ).then((res) => {
        this.table.count = res.count
        this.table.items = res.data
        this.table.busy = false
        this.changeActivity.loading = false
        this.cancelChangeAdminActivity()
      }).catch((err) => {
        if (typeof err === 'object' && err.code === -32602) {
          FormUtils.addValidationErrors(this.changeActivity, err.data)
        } else {
          this.changeActivity.userId.state = false
          this.changeActivity.userId.feedback = 'Произошла ошибка'
        }
        this.table.busy = false
        this.changeActivity.loading = false
      })
    },
    cancelChangeAdminActivity() {
      this.$refs.changeAdminActivityModal.hide()
      this.changeActivity.userId.value = null
      this.changeActivity.userId.feedback = null
      this.changeActivity.userId.state = null
      this.changeActivity.login = null
      this.changeActivity.adminPass.value = null
      this.changeActivity.adminPass.feedback = null
      this.changeActivity.adminPass.state = null
    }
  },
  mounted() {
    this.getAdmins()
  },
  watch: {
    'table.page': function () {
      this.getAdmins()
    }
  }
}
</script>

<style scoped>

</style>