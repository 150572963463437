export default class ChartModel {
    series = []
    labels = []

    add(value, label) {
        this.series.push(this.filterValue(value))
        this.labels.push(label)
    }

    clear() {
        this.series = []
        this.labels = []
    }

    filterValue(val) {
        if (typeof val === 'string') {
            val = parseFloat(val)
        }
        if (typeof val === 'number') {
            val = val.toFixed(3)
            val = parseFloat(val)
        }
        return val
    }
}