import Filters from "./filters";

export default class SearchField {
    code
    name
    placeholder
    type
    sm
    value
    feedback
    state

    constructor(data = {}) {
        this.code = data.code || null
        this.name = data.name || null
        this.placeholder = data.placeholder || null
        this.type = data.type || 'text'
        this.sm = data.sm || 3
        this.value = null
        this.feedback = null
        this.state = null
    }

    filter(val, evt) {
        switch (evt.srcElement.attributes['filter-type'].value) {
            case 'phone':
                return Filters.phoneFilter(val)
            case 'name':
            case 'patronymic':
            case 'surname':
                return Filters.privateNameFilter(val)
            case 'loanId':
                return Filters.loanIdFilter(val)
            default:
                return val
        }
    }

    clearError() {
        this.state = null
        this.feedback = null
    }
}