<template>
  <div>
    <b-row>
      <b-col sm="2">
        <b-form-group label="Выводить по">
          <b-select class="form-control" v-model="table.limit">
            <b-select-option v-for="l in table.limits" :value="l" :key="l">{{ l }}</b-select-option>
          </b-select>
        </b-form-group>
      </b-col>
      <b-col sm="2">
        <b-form-group :label="messageId.label">
          <b-form-input
              type="text"
              :filter-type="messageId.type"
              :placeholder="messageId.placeholder"
              v-model="messageId.value"
              :formatter="messageId.filter"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col sm="2">
        <b-form-group :label="reportId.label">
          <b-form-input
              type="text"
              :filter-type="reportId.type"
              :placeholder="reportId.placeholder"
              v-model="reportId.value"
              :formatter="reportId.filter"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row style="margin-top: 1%">
      <b-col sm="3">
        <b-button-group>
          <b-button variant="success" @click="runSearch">Поиск</b-button>
          <b-button variant="warning" @click="clearSearch">Сброс</b-button>
        </b-button-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12">
        <b-table
          responsive
          striped
          hover
          :busy="table.busy"
          :current-page="table.page"
          :items="table.items"
          :fields="table.fields"
        >
          <template #cell(resend)="{item}">
            <b-button variant="warning" @click="resendMessage(item.id)">Переотправка</b-button>
          </template>
          <template #cell(fileId)="{item}">
            <b-button variant="success" @click="downloadMessage(item.id)">Скачать</b-button>
          </template>
        </b-table>
        <b-pagination
            pills
            v-model="table.page"
            :total-rows="table.count"
            :per-page="table.limit"
        ></b-pagination>
      </b-col>
    </b-row>
    <error-handler :message="errorMessage"></error-handler>
  </div>
</template>

<script>
import Table from "../../mixins/table";
import ErrorHandler from "../ErrorHandler";
import rpc from "../../rpc";
import FormItem from "../../mixins/form-item";

export default {
  name: "FedResursMessageErrors",
  components: {ErrorHandler},
  data() {
    return {
      errorMessage: null,
      messageId: new FormItem({placeholder: 'Введите ID сообщения', label: 'ID сообщения', type: 'loanId'}),
      reportId: new FormItem({placeholder: 'Введите ID отчёта', label: 'ID отчёта', type: 'loanId'}),
      table: new Table({
        limit: 10,
        fields: [
          {
            key: 'id',
            label: 'ID сообщения'
          },
          {
            key: 'created',
            label: 'Дата создания / переотправки'
          },
          {
            key: 'report',
            label: 'Отчёт'
          },
          {
            key: 'errorMessage',
            label: 'Сообщение об ошибке'
          },
          {
            key: 'resend',
            label: 'Переотправка'
          },
          {
            key: 'fileId',
            label: 'Скачать'
          }
        ]
      })
    }
  },
  methods: {
    downloadMessage(id) {
      window.open('/download-fedres-message/' + id, '_blank')
    },
    resendMessage(id) {
      this.table.busy = true
      rpc.resendMessage(id).then(() => {
        this.getTable()
      }).catch(() => {
        this.errorMessage = 'Ошибка переотправки сообщения'
        setTimeout(() => {
          this.errorMessage = null
        }, 5000)
        this.getTable()
      })
    },
    clearSearch() {
      this.messageId.value = null
      this.reportId.value = null
      this.runSearch()
    },
    runSearch() {
      this.table.busy = true
      this.table.page = 1
      this.getTable()
    },
    getTable() {
      this.table.busy = true
      rpc.getErrorMessages(
          this.table.limit,
          this.table.calculateOffset(),
          this.messageId.value,
          this.reportId.value
      ).then((res) => {
        this.table.count = res.count
        this.table.items = res.data
        this.table.busy = false
      }).catch(() => {
        this.errorMessage = 'Ошибка получения данных'
        this.table.count = 0
        this.table.items = []
        this.table.busy = false
        setTimeout(() => {
          this.errorMessage = null
        }, 3000)
      })
    }
  },
  mounted() {
    this.getTable()
  },
  watch: {
    'table.page': function () {
      this.getTable()
    },
    'table.limit': function () {
      this.getTable()
    }
  }
}
</script>

<style scoped>

</style>